import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from 'lodash'
import i18next from 'i18next';

import {
  getIsMobile,
  getLanguage,
  removeToken,
  removeUserZipcode,
  setLanguage,
  setToken,
  setUserZipcode
} from '@/common';
import {
  SHIPPING_ADDRESS_KEY,
  DEFAULT_ZIPCODE,
  LANGUAGE_CHINESE_KEY,
  LANGUAGE_ENGLISH_KEY,
} from '@/constants';
import {
  getApiTokenAuth,
  getCustomerOao,
  getProductGroup
} from '@/api/req-api'

export const userLogin = createAsyncThunk(
  'common/login',
  async ({username, password}, {rejectWithValue}) => {
    try {
      const response = await getApiTokenAuth({
        username,
        password
      });
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const fetchUserInfo = createAsyncThunk(
  'common/userInfo',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getCustomerOao();
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchProductGroup = createAsyncThunk(
    'common/productGroup',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getProductGroup();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isMobile: getIsMobile(),
    locale: getLanguage(),
    loading: false,
    isLogged: false,
    userInfo: null,
    customerDetail: [],
    billingAddress: null,
    productGroupMap: {},
    productCategoryMap: {},
    productGroupMenu: [],
    availableCredit: 0,
    zipcode: DEFAULT_ZIPCODE
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    changeLanguage(state) {
      const language = state.locale === LANGUAGE_ENGLISH_KEY ? LANGUAGE_CHINESE_KEY : LANGUAGE_ENGLISH_KEY
      i18next.changeLanguage(language).then(() => {})
      setLanguage(language)
      state.locale = language
    },
    setIsLogged(state, {payload}) {
      state.isLogged = payload;
    },
    logout(state) {
      state.isLogged = false
      state.userInfo = null
      state.customerDetail = []
      state.billingAddress = null
      state.zipcode = DEFAULT_ZIPCODE
      state.availableCredit = 0
      removeUserZipcode()
      removeToken()
    }
  },
  extraReducers: builder => {
    builder.addCase(userLogin.fulfilled, (state, {payload}) => {
      state.isLogged = true
      setToken(payload.token)
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, {payload}) => {
      state.userInfo = payload
      const customerDetail = payload?.Customer_Detail || []
      state.customerDetail = customerDetail
      const shippingAddress = customerDetail.find(item => item.Type === SHIPPING_ADDRESS_KEY)
      state.shippingAddress = shippingAddress
      const zipcode = shippingAddress?.Zip || DEFAULT_ZIPCODE
      state.zipcode = zipcode
      state.availableCredit = payload.AvailableCredit
      setUserZipcode(zipcode)
    })
    builder.addCase(fetchProductGroup.fulfilled, (state, {payload}) => {
      const group = {}, category = {}, menu = []
      for (let i = 0; i < (payload || []).length; i++) {
        const groupRow = payload[i]
        group[groupRow.ProductGroup_id] = groupRow
        const groupCategories = groupRow.Categories ?? []
        const childrenItem = [];
        for (let j = 0; j < groupCategories.length; j++) {
          const cateRow = groupCategories[j];
          category[cateRow.ProductCategory_id] = {...cateRow, ProductGroup_id: groupRow.ProductGroup_id}
          childrenItem.push({
            label: cateRow.Name,
            key: cateRow.Name,
            description:cateRow.Description,
            name:cateRow.Name,
          })
        }
        menu.push({
          description:groupRow.Description,
          name:groupRow.Name,
          label: groupRow.Name,
          key: groupRow.Name,
          image: groupRow.Image,
          children: childrenItem,
          isEnable: groupRow.IsEnabled,
        })
      }
      state.productGroupMap = group
      state.productCategoryMap = category
      state.productGroupMenu = menu
    })
  },
});

export const {startLoading, stopLoading, changeLanguage, setIsLogged, logout} =
  commonSlice.actions;

export default commonSlice.reducer;
