import axios from 'axios'
import {message} from 'antd'
import {isObject} from 'lodash'

import {
  BASE_URL,
  CONTENT_TYPE,
  JSON_POST,
  LOGOUT_EVENT_NAME,
  START_LOADING_EVENT_NAME,
  STOP_LOADING_EVENT_NAME,
} from '@/constants'
import eventBus from '@/common/EventBus';
import {getToken} from '@/common';

const instance = axios.create({
    baseURL: BASE_URL,
})
instance.defaults.headers.post[CONTENT_TYPE] = JSON_POST;
instance.defaults.withCredentials = false; // 携带cookie
instance.defaults.timeout = 1000*60*5; // 默认请求超时毫秒

//请求拦截器
instance.interceptors.request
  .use(config => {
      const token = getToken()
      eventBus.dispatchEvent(START_LOADING_EVENT_NAME)
      if (token) {
        config.headers.Authorization = `JWT ${token}`
      }
      return config
    }, 
      error => {
        eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
        Promise.reject(error)
      }
);
// 响应拦截器
instance.interceptors.response
  .use(response => {
      eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
      return response
    },
      error => {
        eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
        if (error.response && error.response.status === 401) {
          eventBus.dispatchEvent(LOGOUT_EVENT_NAME)
        }
        let errData = error.response?.data || {}
        if (typeof errData === 'string') {
          errData = {message: errData}
        }
        if (isObject(errData)) {
          const key = Object.keys(errData)[0]
          if (key && Array.isArray(errData[key])) {
            errData.message = `${key}: ${errData[key]}`
          }
        }
        const {message: resMessage} = errData
        if (resMessage) {
          message.error(resMessage)
        }
        return Promise.reject(errData)
      }
);

export default instance
