import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {Empty, Input, Menu, message, Select} from 'antd';

import './index.less'
import './mobile.less'

import {
  checkLogin,
  getDecodeStr,
  getEncodeStr,
  getFormatProductListByCartList,
  getProductItemBtnType,
  getUsingClassName,
} from '@/common';
import {PRODUCT_DEFAULT_LIMIT, LANGUAGE_CHINESE_KEY} from '@/constants';

import storeIcon from '@/assets/img/icon/store.png';
import searchIcon from '@/assets/img/icon/search.png';
import Loading from '@/components/Loading';
import ProductItem from '@/components/ProductItem';
import {
  addProductToFavoriteList,
  getAllProducts,
  getCategoryProducts,
  getGroupProducts,
  getCat2Products,
  removeProductFromFavoriteList
} from '@/api/req-api';
import {addProductToCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';

const searchKey = 'name'
const iconStyle = {width: '16px', height: '16px', verticalAlign: 'text-top'}
const stockOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'instock',
    label: 'In Stock',
  },
  {
    value: 'outofstock',
    label: 'Out of Stock',
  },
]
const sortByOptions = [
  {
    value: 'Name',
    label: 'Product Name',
    icon: <img src={require('../../assets/img/icon/arrowUp.png')} style={iconStyle} alt={'Product Name'}/>,
  },
  {
    value: '-Name',
    label: 'Product Name',
    icon: <img src={require('../../assets/img/icon/arrowDown.png')} style={iconStyle} alt={'Product Name'}/>,
  },
  {
    value: 'Price',
    label: 'Price',
    icon: <img src={require('../../assets/img/icon/arrowUp.png')} style={iconStyle} alt={'Price'}/>,
  },
  {
    value: '-Price',
    label: 'Price',
    icon: <img src={require('../../assets/img/icon/arrowDown.png')} style={iconStyle} alt={'Price'}/>,
  },
  {
    value: 'RefNum',
    label: 'Item No',
    icon: <img src={require('../../assets/img/icon/arrowUp.png')} style={iconStyle} alt={'Item No'}/>,
  },
  {
    value: '-RefNum',
    label: 'Item No',
    icon: <img src={require('../../assets/img/icon/arrowDown.png')} style={iconStyle} alt={'Item No'}/>,
  },
]
const productLimit = PRODUCT_DEFAULT_LIMIT

const defaultProps = {
  className: '',
  rootPath: '',
  scrollTop: 0,
  isEditOrder: false,
  cartProductList: [],
  onAddProduct: () => {},
  onEditProduct: () => {},
  onRemoveProduct: () => {},
};

const ProductsContent = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);
  const {
    rootPath,
    scrollTop,
    isEditOrder,
    cartProductList,
    onAddProduct,
    onEditProduct,
    onRemoveProduct,
  } = options
  
  const {group, category, category2} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const {t} = useTranslation()

  const searchName = searchParams.get(searchKey)
  const name = searchName ? getDecodeStr(searchName) : ''

  const [products, setProducts] = useState([])
  const commonState = useSelector(state => state.common)
  const {productGroupMenu, productCategoryMap, productGroupMap, isLogged, locale} = commonState
  const rootClassName = commonState.isMobile ? 'products-content-mobile-container' : 'products-content-container'
  
  const isCat2Page = category2 !== undefined
  const productGroupMapKeys = Object.keys(productGroupMap)
  const productCategoryMapKeys = Object.keys(productCategoryMap)
  const [selectedKeys, setSelectedKeys] = useState('')
  const [openKeys, setOpenKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const [groupDetail, setGroupDetail] = useState({})
  const [menuList, setMenuList] = useState([])
  const [categoryDetail, setCategoryDetail] = useState({})
  const filterRef = useRef()
  const maskRef = useRef()
  const searchInputRef = useRef()
  const touchBottom = useRef()
  const breadCrumbRef = useRef()

  const [searchVal, setSearchVal] = useState('')
  // 设置默认值为null，只有初次进页面会是默认值，清除选择后为undefined
  const [sortByVal, setSortByVal] = useState(null)
  const [sortByStock, setSortByStock] = useState('all')
   
  // 设置默认值为0，只有初次进页面会是默认值，切换组或者分类会设置为1
  const [pageNum, setPageNum] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const useProducts = useMemo(() => {
    return getFormatProductListByCartList(products, cartProductList)
  }, [products, cartProductList])

  useEffect(() => {
    if (!commonState.isMobile) {
      window.addEventListener('scroll', handlePageScroll)
      // let observer = new IntersectionObserver(function(entries) {
      //   if(entries[0].isIntersecting === true) {
      //     console.log('Element has just become visible in screen');
      //     filterRef.current.classList.remove('fixed')
      //     breadCrumbRef.current.classList.remove('fixed')
      //     touchBottom.current = true
      //   }
      //   if(entries[0].isIntersecting === false) {
      //     console.log('Element has just become visible out screen');
      //     if(touchBottom.current === true) {
      //       filterRef.current.classList.add('fixed')
      //       breadCrumbRef.current.classList.add('fixed')
      //     }
      //     touchBottom.current = false
      //   }
      // }, { threshold: [0] });
      //
      // observer.observe(document.querySelector("#page-footer"));
      return () => {
        window.removeEventListener('scroll', handlePageScroll)
        // observer.unobserve(document.querySelector("#page-footer"))
        // observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    setSearchVal(name)
  }, [name])

  const fetchGroupProducts = (groupId, sort, stock, pageNum) => {
    setLoading(true)
    getGroupProducts(groupId, sort, stock, pageNum).then(response => {
      console.log('fetchGroupProducts response: ', response);
      const resData = response.data
      setTotalPages(response.data.pages)
      const newList = []
      if (pageNum > 1) {
        newList.push(...products)
      }
      newList.push(...resData)
      setProducts(newList)
      setLoading(false)
    })
  }

  const fetchCategoryProducts = (categoryId, sort, stock, pageNum) => {
    setLoading(true)
    getCategoryProducts(categoryId, sort, stock, pageNum).then(response => {
      console.log('fetchCategoryProducts response: ', response);
      const resData = response.data
      setTotalPages(response.data.pages)
      const newList = []
      if (pageNum > 1) {
        newList.push(...products)
      }
      newList.push(...resData)
      setProducts(newList)
      setLoading(false)
    })
  }
  
  const fetchAllProducts = (name, sortByVal, sortByStock, pageNum) => {
    setLoading(true)
    getAllProducts(name || undefined, sortByVal, sortByStock, pageNum).then(response => {
      console.log('fetchAllProducts response: ', response);
      const resData = response.data.Products
      setTotalPages(response.data.pages)
      const newList = []
      if (pageNum > 1) {
        newList.push(...products)
      }
      newList.push(...resData)
      setProducts(newList)
      setLoading(false)
    })
  }
  
  const fetchCat2Products = (category2Id) => {
    // no pagiantion for cate2 b2b list
    setLoading(true)
    getCat2Products(category2Id).then(response => {
      console.log('fetchCat2Products response: ', response);
      const resData = response.data
      const newList = []
      newList.push(...resData)
      setProducts(newList)
      setLoading(false)
    })
  }
  
  const initPageData = () => {
    if (productGroupMapKeys.length <= 0) {
      return
    }
    
    if (category2) {
      fetchCat2Products(category2)
      return
    }
    
    if (!group) {
      fetchAllProducts(name, sortByVal, sortByStock, pageNum)
      return
    }
    const groupId = productGroupMapKeys.find(key => productGroupMap[key].Name.toLowerCase() === getDecodeStr(group))
    if (!groupId) {
      return
    }
    const groupKey = productGroupMap[groupId].Name
    setGroupDetail(productGroupMap[groupId])
    setOpenKeys([groupKey])
    if (!category) {
      fetchGroupProducts(groupId, sortByVal, sortByStock, pageNum)
      setCategoryDetail({})
    }
    const categoryId = productCategoryMapKeys.find(key => productCategoryMap[key].Name.toLowerCase() === getDecodeStr(category))
    if (!categoryId) {
      return
    }
    
    fetchCategoryProducts(categoryId, sortByVal, sortByStock, pageNum)
    setCategoryDetail(productCategoryMap[categoryId])
    setSelectedKeys(categoryDetail.Name)
  }
  useEffect(() => {
    // 如果是默认值，不需要调接口，别的地方会调
    if (pageNum < 1) {
      return
    }
    initPageData()
  }, [pageNum])
  useEffect(() => {
    // 如果是默认值，不需要调接口，别的地方会调
    if (sortByVal === null) {
      return
    }
    initPageData()
  }, [sortByVal])
  
  useEffect(() => {
    // ?
    // if (sortByStock === 'all') {
    //   return
    // }
    initPageData()
  }, [sortByStock])
  
  useEffect(() => {
    if (pageNum !== 1) {
      return setPageNum(1)
    }
    initPageData()
  }, [group, category, productCategoryMap, name])

  useEffect(()=>{
    if(productGroupMenu.length > 0) {
      let tList = [];
      productGroupMenu.map((item)=>{
        if(locale == LANGUAGE_CHINESE_KEY) {
          let chList = [];
          item.children && item.children.length > 0 && item.children.map((childIt)=>{
            chList.push({key: childIt.key, label: childIt.description}) 
          })
          tList.push({key: item.key, children: chList, label: item.description}) 
        } else {
          tList.push({key: item.key, children: item.children, label: item.name}) 
        }
      })
      setMenuList(tList)
    }
  },[productGroupMenu, locale])
  const handleMenuClick = event => {
    if (commonState.isMobile) {
      handleFilterClick();
    }
    const categoryKey = event.key.toLowerCase()
    navigate(`${rootPath}/${groupDetail.Name.toLowerCase()}/${categoryKey}`, {replace: true})
  };

  const handleMenuOpen = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey])
      navigate(`${rootPath}/${latestOpenKey.toLowerCase()}`, {replace: true})
    } else {
      setOpenKeys([])
    }
  }

  const handleProductItemClick = item => {
    if (isEditOrder) {
      const path = `/editOrderProductDetail/${item.ProductNum}`
      navigate(path)
      return 
    }
    const path = `/productDetail/${item.ProductNum}`
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }

  const handleEditOrderMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return onRemoveProduct(item)
    }
    onEditProduct(item, newQty)
  }
  const handleMinBtnClick = (item, qty) => {
    if (isEditOrder) {
      return handleEditOrderMinBtnClick(item, qty)
    }
    const newQty = qty - 1
    if (newQty < 1) {
      return dispatch(removeCartItem({item}))
    }
    dispatch(updateCartItemQuantity({item, quantity: newQty}))
  }
  const handleAddToCart = item => {
    if (isEditOrder) {
      return onAddProduct(item)
    }
    dispatch(addProductToCart({item}))
  }

  const handleBeforeAddToCart = item => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleAddToCart(item)
    )
  }

  const handleChangeFavoriteStatus = item => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    const newProducts = cloneDeep(products)
    const idx = newProducts.findIndex(ite => ite.Product_id === item.Product_id)
    if (idx < 0) {
      return
    }
    fun(item).then(() => {
      message.success(msg)
      newProducts.splice(idx, 1, {...item, IsFavorite: !isFavorite})
      setProducts(newProducts)
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = item => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item))
  }

  const handleClearFilterClick = () => {
    setOpenKeys([])
    setGroupDetail({})
    setCategoryDetail({})
    navigate(`${rootPath}`, {replace: true})
  }

  const handleSearch = () => {
    setOpenKeys([])
    setGroupDetail({})
    setCategoryDetail({})
    const keywords = searchInputRef.current.input.value
    const searchParams = new URLSearchParams({[searchKey]: getEncodeStr(keywords)})
    navigate(`${rootPath}?${searchParams}`, {replace: true})
  }
  const handleSearchInputChange = event => {
    setSearchVal(event.target.value)
  }

  const handleSortByChange = newSortByVal => {
    setSortByVal(newSortByVal)
  }
  
  const handleSortByStockChange = newSortByStock => {
    setSortByStock(newSortByStock)
  }

  const handleShowMore = () => {
    setPageNum(pageNum + 1)
  }

  const renderRightSearchIcon = () => <div className={'products-content-right-search-icon-container'}>
    <img src={searchIcon} alt="" onClick={handleSearch}/>
  </div>

  const renderProductGroupItem = (item, index) => <div
    className={'products-content-right-product-group-item-container'}
    key={`product_group_item_${index}`}>
    <ProductItem
      packageType={item.MeasureUnit}
      img={item.ThumbnailLarge || item.Thumbnail}
      price={item.Price}
      name={item.Name}
      description={item.Description}
      onProductItemClick={() => handleProductItemClick(item)}
      productType={item.ProductType}
      isShowPrice={isLogged}
      onBtnClick={() => handleBeforeAddToCart(item)}
      isFormatPriceToMoney={true}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => handleAddToCart(item)}
      isPromotion={item.IsPromotion}
      stock={item.StockLevel}
      isOverSaleable={item.IsOverSaleable}
      discountDescription={item.DiscountDescription}
      originalPrice={item.OriginalPrice}
    />
  </div>
  const renderProductGroup = () => <div className={'products-content-right-product-group-container'}>
    {
      !products.length &&
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
    {
      !!products.length &&
      <>
        <div className={'products-content-right-product-group-list'}>
          {useProducts.map((item, index) => renderProductGroupItem(item, index))}
        </div>
        {
          products.length >= productLimit && pageNum < totalPages && !isCat2Page &&
          <div
            className={'products-content-right-product-group-btn'}
            onClick={handleShowMore}>
            {t('Click to show more')}
          </div>
        }
      </>
    }
  </div>

  const handlePageScroll = event => {
    if(event.target.documentElement.scrollTop >= scrollTop) {
      if (!touchBottom.current) {
        filterRef.current.classList.remove('stickBottom')
        filterRef.current.classList.add('fixed')
        breadCrumbRef.current.classList.remove('stickTop')
        breadCrumbRef.current.classList.add('fixed')
        maskRef.current.classList.add('mask_fixed')
      } else {
        filterRef.current.classList.add('stickBottom')
        breadCrumbRef.current.classList.add('stickTop')
      }
    } else {
      filterRef.current.classList.remove('fixed')
      breadCrumbRef.current.classList.remove('fixed')
      maskRef.current.classList.remove('mask_fixed')
    }
  }
  const handleFilterClick = () => {
    if (commonState.isMobile) {
      const filterDom = document.querySelector('.products-content-filter-container')
      const isExtended = filterDom.classList.contains('extended');
      if (isExtended) {
        filterDom.classList.remove('extended');
      } else {
        filterDom.classList.add('extended');
      }
    }
  }
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  const getProductsTitle = () => {
    
    let group = groupDetail.Name ? ` > ${groupDetail.Name}` : '';
    let category = categoryDetail.Name ? ` > ${categoryDetail.Name}` : ''
    if(locale == LANGUAGE_CHINESE_KEY) {
      group = groupDetail.Description ? ` > ${groupDetail.Description}` : ''
      category = categoryDetail.Description ? ` > ${categoryDetail.Description}` : ''
    }
    return group + category;
  }

  useImperativeHandle(ref, () => ({
    handlePageScroll
  }))
  const showClearFilter = location.pathname + location.search !== rootPath;
  return (
    <div className={className}>
      <div className={isCat2Page ? 'hide' : 'products_mask'} ref={maskRef}/>
      <div className={isCat2Page ? 'hide' : 'products-content-filter-container'} ref={filterRef}>
        <div className={'products-content-filter-top-container'} onClick={handleFilterClick}>
          <div className={'products-content-filter-top-title'}>{t('Filter')}</div>
          <div
            className={'products-content-filter-top-clear-filter'}
            onClick={handleClearFilterClick}>
            {showClearFilter && t('Clear filter')}
          </div>
        </div>
        <Menu
          className={'products-content-filter-menu-container'}
          onClick={handleMenuClick}
          style={{activeBarBorderWidth:0}}
          onOpenChange={handleMenuOpen}
          activeKey={selectedKeys}
          // defaultSelectedKeys={defaultSelectedKeys}
          openKeys={openKeys}
          mode="inline"
          items={menuList}
        />
      </div>
      <div className={(isCat2Page && !commonState.isMobile) ? 'products-content-one-container' : 'products-content-right-container'}>
        <div className={'products-content-right-title-container'} ref={breadCrumbRef}>
          <div className={'products-content-right-title-icon-container'}>
            <img src={storeIcon} alt=""/>
          </div>
          <div>{t('All Product')}{getProductsTitle()}</div>
        </div>
        <div className={'products-content-right-search-sort-container'}>
          <Input
            ref={searchInputRef}
            className={'products-content-right-search-container'}
            suffix={renderRightSearchIcon()}
            placeholder={t('Search by Product Name')}
            onPressEnter={handleSearch}
            value={searchVal}
            onChange={handleSearchInputChange}
          />
          <div className={'products-content-right-sort-container'}>
            <Select
              className={'products-content-right-sort-select-container'}
              popupClassName={'products-content-right-sort-select-popup-container'}
              value={sortByStock}
              onChange={handleSortByStockChange}
              placeholder={t('Sort By')}>
              {
                stockOptions.map((item, index) => {
                  const currentSortOption = item.value.includes(sortByStock)
                  return <Select.Option
                    value={item.value}
                    key={`sort_by_options_${index}`}>
                      <span
                        className={'products-content-right-sort-select-option-label'}>
                        {t(item.label)}
                      </span>
                  </Select.Option>
                })
              }
            </Select>
            <Select
              className={'products-content-right-sort-select-container'}
              popupClassName={'products-content-right-sort-select-popup-container'}
              value={sortByVal}
              onChange={handleSortByChange}
              // options={sortByOptions}
              placeholder={t('Sort By')}
              allowClear={true}>
              {
                sortByOptions.map((item, index) => {
                  const defaultVal = item.value.replace(/^-/, '')
                  const currentSortOption = item.value.includes(sortByVal)
                  const isDesc = sortByVal ? sortByVal.startsWith('-') : false
                  const isDescOption = item.value.startsWith('-')
                  let style = {}
                  if (currentSortOption) {
                    if (isDesc) {
                      if (isDescOption) {
                         style = {display: 'none'}
                      }
                    } else {
                      if (!isDescOption) {
                         style = {display: 'none'}
                      }
                    }
                  } else {
                    if (item.value !== defaultVal) {
                      style = {display: 'none'}
                    }
                  }
                  return <Select.Option
                    style={style}
                    value={item.value}
                    key={`sort_by_options_${index}`}>
                        <span
                          className={'products-content-right-sort-select-option-label'}>
                          {t(item.label)}
                        </span>
                    {
                      item.icon &&
                      <span
                        className={'products-content-right-sort-select-option-icon'}>
                            {item.icon}
                          </span>
                    }
                  </Select.Option>
                })
              }
            </Select>
          </div>
        </div>
        {loading && <Loading text={'Loading Products'} />}
        {renderProductGroup()}
      </div>
    </div>
  )
})

export default ProductsContent
