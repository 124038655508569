import React, {useState, useImperativeHandle, forwardRef} from 'react';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react-18-support';

import './index.less'

import {googleStyle} from '@/common/googleMapStyle'

const recordsObj = {}

const defaultProps = {
  className: '',
  visible: true,
  initialCenter: {
    lat: 40.769524,
    lng: -73.8407036,
  },
  zoom: 13,
  maxZoom: 21,
  minZoom: 8,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  panControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const GoogleMaps = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const [autocompleteService, setAutocompleteService] = useState(null)
  const [google, setGoogle] = useState(null)
  const [activeMarker, setActiveMarker] = useState({})
  const [isShowInfoWindow, setIsShowInfoWindow] = useState(false)
  
  let getSuggestionsListTimeoutId = null
  const getSuggestionsListByText = (input, callback) => {
    if (getSuggestionsListTimeoutId) {
      clearTimeout(getSuggestionsListTimeoutId)
      getSuggestionsListTimeoutId = null
    }
    getSuggestionsListTimeoutId = setTimeout(() => {
      let result = []
      if (!input) {// 输入的值为空，返回空数组
        console.log('输入的值为空，返回空数组');
        return callback(result)
      }
      if (recordsObj[input]) {// 有记录，使用记录返回值
        console.log('有记录，使用记录返回值');
        return callback(recordsObj[input])
      }
      autocompleteService.getQueryPredictions({
        input,
        componentRestrictions: {
          country: ['us']
        }
      }, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
          // 参数不对，返回默认的空数组
          console.log('参数不对，返回默认的空数组');
          return callback(result)
        }
        result = predictions.map(item => {
          const {description, place_id} = item
          return {
            ...item,
            label: description,
            value: description
          }
        })
        // 将搜索结果记录下来
        recordsObj[input] = result
        console.log('将搜索结果记录下来');
        callback(result)
      })
    }, 10)
  }
  const onReady = (mapProps, map) => {
    const {google} = mapProps;
    const autocompleteService = new google.maps.places.AutocompleteService(map);
    setGoogle(google);
    setAutocompleteService(autocompleteService)
  }
  
  const handleMarkerClick = (mapProps, marker, event) => {
    setActiveMarker(marker)
    setIsShowInfoWindow(true)
  }
  const handleInfoWindowClose = () => {
    setActiveMarker(null)
    setIsShowInfoWindow(false)
  }

  useImperativeHandle(ref, () => ({
    getSuggestionsListByText,
  }))
  
  return (
    <Map
      visible={options.visible}
      styles={googleStyle}
      google={options.google}
      zoom={options.zoom}
      maxZoom={options.maxZoom}
      minZoom={options.minZoom}
      zoomControl={options.zoomControl}
      mapTypeControl={options.mapTypeControl}
      scaleControl={options.scaleControl}
      streetViewControl={options.streetViewControl}
      panControl={options.panControl}
      rotateControl={options.rotateControl}
      fullscreenControl={options.fullscreenControl}
      center={options.initialCenter}
      initialCenter={options.initialCenter}
      onReady={onReady}>
      <Marker
        options={{
          position: options.initialCenter
        }}
        onClick={handleMarkerClick}
      />
      <InfoWindow
        marker={activeMarker}
        visible={isShowInfoWindow}
        onClose={handleInfoWindowClose}>
        <div>
          <h3>LMH Building Supply</h3>
          <div>1748 Junction Ave, San Jose, CA 95112</div>
        </div>
      </InfoWindow>
    </Map>
  );
})

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA8yIWNfPAvSLVw6cTd29UKcklmvKsdlDA',
})(GoogleMaps)
