import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import {message} from 'antd';

import './index.less'
import './mobile.less'

import UserInfoForm from '@/components/UserInfoForm'
import SignupSuccess from '@/components/SignupSuccess'

import {getUsingClassName} from '@/common';
import {registerBusinessCustomer, baseUserCheckTaken} from '@/api/req-api'

// const str = 'olong-scm-001'
// const initialValues = {
//   name: `name ${str}`,
//   emailAddress: `${str}@mailinator.com`,
//   phoneNumber: `phoneNumber${str}`,
//   company: `company${str}`,
//   companyPhone: `companyPhone${str}`,
//   businessType: `businessType${str}`,
//   einNumber: `einNumber${str}`,
//   address: `address${str}`,
//   city: `city${str}`,
//   state: `state${str}`,
//   zipCode: `11354`,
// }

const defaultProps = {
  title: '',
  className: '',
  onRegisterAccountSuccess: () => {
  },
};

const RegisterAccount = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'register-account-mobile-container' : 'register-account-container'

  const signupSuccessRef = useRef(null);
  const userInfoFormRef = useRef(null);

  const resetFields = () => userInfoFormRef.current?.resetFields()
  const onFormFinish = async (values) => {
    const emailAddress = values.emailAddress
    const res = await baseUserCheckTaken({email: emailAddress})
    if (res.data.isTaken) {
      return message.error('User with this email already exists')
    }
    const params = {
      full_name: values.name,
      email: emailAddress,
      phone_number: values.phoneNumber,
      business_name: values.company,
      business_phone: values.companyPhone,
      business_type: values.businessType,
      ein: values.einNumber,
      street: values.address,
      city: values.city,
      state: values.state,
      zipcode: values.zipCode,
      country: 'US'
    }
    registerBusinessCustomer(params)
      .then(() => {
        resetFields()
        signupSuccessRef.current?.showModal()
        options.onRegisterAccountSuccess(values)
      })
  }
  const handleRegisterAccountClick = () => {
    userInfoFormRef.current?.submit()
  }

  const handleTermsOfServiceClick = () => {
    navigate('/termsOfService')
  }
  const handlePrivacyPolicyClick = () => {
    navigate('/privacyPolicy')
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    resetFields,
  }))

  return (
    <div className={className}>
      <div className={'register-account-title'}>
        {t('Start business with us')}
      </div>
      <UserInfoForm
        ref={userInfoFormRef}
        isShowBaseInfo={true}
        isShowCompanyInfo={true}
        isShowAddressInfo={true}
        isShowPassword={false}
        isRequiredBusinessType={true}
        onFormFinish={onFormFinish}
      />
      <div className={'register-account-tip-container'}>
        <span>{t('By continuing, you agree to')} LMH Building Supply.</span>
        <span className={'register-account-tip-link'} onClick={handleTermsOfServiceClick}>
          {t('Terms of Service')}
        </span>
        <span> {t('and')} </span>
        <span className={'register-account-tip-link'} onClick={handlePrivacyPolicyClick}>
          {t('Privacy Policy')}
        </span>
      </div>
      <div className={'register-account-btn'} onClick={handleRegisterAccountClick}>
        {t('Register Account')}
      </div>
      <SignupSuccess ref={signupSuccessRef} />
    </div>
  )
})

export default RegisterAccount
