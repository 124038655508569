import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import WechatPopover from '@/components/WechatPopover'
import logoImg from '@/assets/img/logo.png'
import wechatImg from '@/assets/img/icon/wechat.png'
import instagramImg from '@/assets/img/icon/instagram.png'
import websiteImg from '@/assets/img/icon/website.png'
import {WEBSITE_LINK, IG_LINK} from '@/constants';

const defaultProps = {
  isShowFooterTop: true,
};

export default function Footer(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'footer-mobile-container' : 'footer-container'
  
  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  
  return (
    <div className={rootClassName} id={'page-footer'}>
      {
        options.isShowFooterTop &&
        <div className={'footer-top-container'}>
          <div className={'footer-top-content-container'}>
            <div className={'footer-top-content-left-container'}>
              <div className={'footer-top-content-left-logo-container'}>
                <img src={logoImg} alt="logo" />
              </div>
              <div className={'footer-top-content-left-des'}>
                LMH Building Supply was established in San Jose, CA.
              </div>
              <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title'}>{t('SOCIAL MEDIAS')}</div>
                <div className={'footer-top-content-left-get-in-touch-list'}>
                  <WechatPopover>
                    <div 
                      className={'footer-top-content-left-get-in-touch-item-container'}>
                      <img src={wechatImg} alt="wechat" />
                    </div>
                  </WechatPopover>
                  <div 
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(IG_LINK)}>
                    <img src={instagramImg} alt="instagram" />
                  </div>
                  <div 
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(WEBSITE_LINK)}>
                    <img src={websiteImg} alt="facebook" />
                  </div>
                </div>
              </div>
            </div>
            <div className={'footer-top-content-right-container'}>
              <div className={'footer-top-content-right-working-hours-container'}>
                <div className={'footer-top-content-title'}>{t('WORKING HOURS')}</div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Monday - Friday')}
                  <span>: {t('8:30am - 5:30pm')}</span>
                </div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Saturday')}
                  <span>: {t('9am - 3pm')}</span>
                </div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Sunday')}
                  <span>: {t('closed')}</span>
                </div>
              </div>
              <div className={'footer-top-content-right-contact-us-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleContactUsClick}>{t('CONTACT US')}</div>
                <div className={'footer-top-content-right-contact-us-title'}>
                  1748 Junction Ave, San Jose, CA 95112
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  {t('Phone')}: (650) 698-3333
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={'footer-bottom-container'}>
        © 2024 LMH Building Supply | Powered by TruckXi
      </div>
    </div>
  )
}
