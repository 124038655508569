import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from 'lodash'
import {message} from 'antd'

import i18next from '@/i18next'
import {getOrderCart, addToCart, updateItemQuantity, deleteCartItem} from '@/api/req-api'

export const fetchOrderCart = createAsyncThunk(
    'cart/orderCart',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getOrderCart();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const addProductToCart = createAsyncThunk(
    'cart/addToCart',
    async ({item, quantity, isFetchOrderCart = true, successIsTip = true}, {rejectWithValue, dispatch}) => {
      try {
        const response = await addToCart(item, quantity);
        isFetchOrderCart && dispatch(fetchOrderCart())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const removeCartItem = createAsyncThunk(
  'cart/deleteCartItem',
  async ({item}, {rejectWithValue, dispatch}) => {
    try {
      const response = await deleteCartItem(item);
      dispatch(fetchOrderCart())
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
)
export const updateCartItemQuantity = createAsyncThunk(
    'cart/updateItemQuantity',
    async ({item, quantity}, {rejectWithValue, dispatch}) => {
      try {
        const response = await updateItemQuantity(item, quantity);
        dispatch(fetchOrderCart())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)


const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    orderCartInfo: null,
    orderCartProductList: [],
    orderCartTotalCount: 0
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderCart.fulfilled, (state, {payload}) => {
      state.orderCartInfo = payload
      state.orderCartProductList = payload.Sales_Order_Lines || []
      state.orderCartTotalCount = payload.TotalCount || 0
    })
    builder.addCase(addProductToCart.fulfilled, (state, {payload, meta}) => {
      state.orderCartTotalCount = payload.total_count || 0
      if (meta.arg.successIsTip === false) {// 必须判断false，如果没传此处获取不到该属性
        return
      }
      message.success(i18next.t('Added successfully'))
    })
  },
});

export default cartSlice.reducer;
