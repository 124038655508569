import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {InputNumber, Checkbox} from 'antd';

import './index.less'
import './mobile.less'

import noImage from '@/assets/img/icon/noImage.png'
import favoriteActiveIcon from '@/assets/img/icon/favoriteActive.png'

import {getFormatMoney} from '@/common';
import {PRODUCT_ITEM_BTN_TYPE_3} from "@/constants";

const defaultProps = {
  img: noImage,
  price: '',
  des: '',
  quantity: 1,
  btnText: 'Add to cart',
  totalPrice: 0,
  onProductItemClick: () => {},
  onBtnClick: () => {},
  productType: null,
  isShowPrice: true,
  checked: false,
  isCheck: false,
  isFormatPriceToMoney: false,
  isFavorite: false,
  isOrder: false,
  isPromotion: false,
  onFavoriteIconClick: () => {},
  onQuantityChange: () => {},
  onCheckChange: () => {},
  stock: 999,
  isOverSaleable: false,
}
export default function ProductItem2(props) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'product-item-2-mobile-container' : 'product-item-2-container'
  
  const {price, isShowPrice, isFormatPriceToMoney, isCheck, onCheckChange} = options
  const isOutOfStock = options.isOverSaleable ? false : options.stock <= 0
  if (isOutOfStock) {
    options.btnText = 'Out of stock'
  }
  const handleProductItemClick = () => {
    options.onProductItemClick()
  }
  const handleBtnClick = (event) => {
    if (isOutOfStock) {
      return;
    }
    event.preventDefault();
    event.stopPropagation()
    options.onBtnClick()
  }
  const handleFavoriteIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
    options.onFavoriteIconClick()
  }
  const handleBottomClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
  }
  return (
    <div className='product_item2_row'>
      {options.isCheck && <Checkbox className='check_box' checked={options.checked} onChange={()=>{options.onCheckChange()}}/>}
      <div className={rootClassName} onClick={handleProductItemClick}>
      <div className={'product-item-2-img-container'}>
        {options.img ? <img src={options.img} alt="" /> : <img src={noImage} alt="" />}
        {options.isPromotion && <div className="product-item-2-promotion-icon-container">
          <img src={require('../../assets/img/icon/discountIcon.png')} alt="" />
        </div>}
      </div>
      <div className={'product-item-2-content-container'}>
        <div className={'product-item-2-des overflow-ellipsis-1'}>
          {options.des}
        </div>
        {
          isShowPrice &&
          <div className={'product-item-2-price overflow-ellipsis-1'}>
            <span>${isFormatPriceToMoney ? getFormatMoney(price) : price}</span>
          </div>
        }
        {
          options.isFavorite &&
          <div
            className={'product-item-2-bottom-container'}
            onClick={handleBottomClick}>
            <InputNumber
              className={'product-item-2-bottom-input'}
              min={1}
              max={options.isOverSaleable ? undefined : options?.stock}
              disabled={isOutOfStock}
              value={options.quantity}
              onChange={options.onQuantityChange}
            />
            <div className={isOutOfStock ? 'product-item-2-btn-disabled' : 'product-item-2-btn'} onClick={handleBtnClick}>
              {t(options.btnText)}
            </div>
          </div>
        }
        {
          options.isOrder && <div className={'product-item-2-placeholder'}></div>
        }
      </div>
      <div className={'product-item-2-right-container'}>
        {
          options.isFavorite &&
          <div className={'product-item-2-right-favorite-icon-container'} onClick={handleFavoriteIconClick}>
            <img src={favoriteActiveIcon} alt="" />
          </div>
        }
        {
          options.isOrder &&
          <>
            <div className={'product-item-2-right-total-price'}>
              ${options.totalPrice}
            </div>
            <div className={'product-item-2-right-qty'}>
              {t('QTY')}: {options.quantity}
            </div>
            <div className={'product-item-2-placeholder'}></div>
          </>
        }
      </div>
      </div>
    </div>
  )
}
