import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import GetInTouch from '@/components/GetInTouch'
import GoogleMaps from '@/components/GoogleMaps'
import WechatPopover from '@/components/WechatPopover'

import bannerImg from '@/assets/img/contactUs/banner.jpg';
import wechatImg from '@/assets/img/icon/wechat.png';
import instagramImg from '@/assets/img/icon/instagram.png';
import facebookImg from '@/assets/img/icon/facebook.png';
import {FACEBOOK_LINK, IG_LINK} from "@/constants";

export default function ContactUs() {
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'contact-us-mobile-container' : 'contact-us-container'
  
  return (
    <Layout isShowFooterTop={false}>
      <div className={rootClassName}>
        <ContentHeader
          className={'contact-us-header-container'}
          des={t('CONTACT US')}
          title={t('Get in Touch with Us: Contact Our Friendly Team Today!')}
          banner={bannerImg}
        />
        <div className={'contact-us-content-container'}>
          <GetInTouch
            className={'contact-us-content-left-container'}
          />
          <div className={'contact-us-content-right-container'}>
            <div className={'contact-us-content-right-working-hours-container'}>
              <div className={'contact-us-content-right-title'}>{t('WORKING HOURS')}</div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                {t('Monday - Saturday')}
                <span>: {t('8am - 5pm')}</span>
              </div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                {t('Sunday')}
                <span>: {t('9am - 3pm')}</span>
              </div>
            </div>
            <div className={'contact-us-content-right-contact-us-container'}>
              <div className={'contact-us-content-right-title'}>{t('CONTACT US')}</div>
              <div className={'contact-us-content-right-contact-us-title'}>
                1889 Wheeler Ave La Verne
              </div>
              <div className={'contact-us-content-right-contact-us-des'}>
                CA 91750
              </div>
              <div className={'contact-us-content-right-contact-us-des'}>
                {t('Phone')}: 909-923-1388
              </div>
            </div>
            <div className={'contact-us-content-right-social-medias-container'}>
              <div className={'contact-us-content-right-title'}>{t('SOCIAL MEDIAS')}</div>
              <div className={'contact-us-content-right-social-medias-list'}>
                <WechatPopover>
                  <div className={'contact-us-content-right-social-medias-item-container'}>
                    <img src={wechatImg} alt="wechat" />
                  </div>
                </WechatPopover>
                <div 
                  className={'contact-us-content-right-social-medias-item-container'}
                  onClick={() => window.open(IG_LINK)}
                >
                  <img src={instagramImg} alt="instagram" />
                </div>
                <div 
                  className={'contact-us-content-right-social-medias-item-container'}
                  onClick={() => window.open(FACEBOOK_LINK)}>
                  <img src={facebookImg} alt="facebook" />
                </div>
              </div>
            </div>
            <div className={'contact-us-content-right-map-container'}>
              <GoogleMaps zoomControl={!commonState.isMobile}/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
