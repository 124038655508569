import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {message} from 'antd';
import {isFunction} from 'lodash'

import './index.less'
import './mobile.less'

import {getUsingClassName, getFormatDate} from '@/common';
import {getSalesOrderDetails} from '@/api/req-api';
import {addProductToCart, fetchOrderCart} from '@/store/modules/cart.slice';

const format = 'MMM D, YYYY'

const defaultProps = {
  className: '',
  item: {},
  onViewOrderDetailsClick: () => {},
  onReorder: undefined,
  isShowViewOrderDetailsBtn: true,
  isShowReorderBtn: true,
  isShowReturnTotal: false,
  isShowInvoiceTotal: false,
  opBtnId: undefined,
  isShowEditBtn: false,
  isShowVoidBtn: false,
  onEditClick: () => {},
  onVoidClick: () => {},
};

export default function MyOrderItem(props) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const options = Object.assign({}, defaultProps, props);
  const {item} = options

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-order-item-mobile-container' : 'my-order-item-container'

  const handleReorderClick = () => {
    if (isFunction(options.onReorder)) {
      return options.onReorder()
    }
    getSalesOrderDetails(item.SalesOrder_id).then(res => {
      const salesOrderLine = res.data?.Sales_Order_Line
      if (!Array.isArray(salesOrderLine)) {
        return
      }
      const promiseList = []
      salesOrderLine.forEach(item => {
        promiseList.push(dispatch(addProductToCart({item, quantity: item.Quantity, isFetchOrderCart: false, successIsTip: false})))
      })
      Promise.all(promiseList).then(() => {
        message.success(t('Successful operation'))
        dispatch(fetchOrderCart())
      })
    })
  }
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      <div className={'my-order-item-content'}>
        <div className={'my-order-item-row-1'}>
          <div className={'my-order-item-label'}>{t('SO NO')} #</div>
          <div className={'my-order-item-value'}>{item.SONum}</div>
        </div>
        <div className={'my-order-item-row-2'}>
          <div className={'my-order-item-label'}>{t('Order Date')}</div>
          <div className={'my-order-item-value'}>{getFormatDate(item.OrderDate, format, 'es')}</div>
        </div>
        <div className={'my-order-item-row-2'}>
          <div className={'my-order-item-label'}>{t('Delivery Estimate')}</div>
          <div className={'my-order-item-value'}>{getFormatDate(item.DispatchDate, format, 'es')}</div>
        </div>
        <div className={'my-order-item-row-2'}>
          <div className={'my-order-item-label'}>{t('Order Total')}</div>
          <div className={'my-order-item-value'}>${item.FinalPrice}</div>
        </div>
        {
          !!item.invoice_total && options.isShowInvoiceTotal &&
          <div className={'my-order-item-row-2'}>
            <div className={'my-order-item-label'}>{t('Invoice Total')}</div>
            <div className={'my-order-item-value'}>${item.invoice_total}</div>
          </div>
        }
        {
          !!item.return_total && options.isShowReturnTotal &&
          <div className={'my-order-item-row-2'}>
            <div className={'my-order-item-label'}>{t('Return Total')}</div>
            <div className={'my-order-item-value'}>${item.return_total}</div>
          </div>
        }
        <div className={'my-order-item-row-2'}>
          <div className={'my-order-item-label'}>{t('Order Status')}</div>
          <div className={`${(item.SaleStatus==='Canceled') ? 'hot my-order-item-value' : 'my-order-item-value'}`}>{item.SaleStatus}</div>
          
        </div>
        <div className={'my-order-item-row-2'}>
          <div className={'my-order-item-label'}>{t('Order Person')}</div>
          <div className={'my-order-item-value'}>{item.Order_Person}</div>
        </div>
      </div>
      {
        (options.isShowViewOrderDetailsBtn || options.isShowReorderBtn) &&
        <div className={'my-order-item-bottom-container'} id={options.opBtnId}>
          {
            options.isShowViewOrderDetailsBtn &&
            <div
              className={'my-order-item-btn'}
              onClick={options.onViewOrderDetailsClick}>
              {t('View Order Details')}
            </div>
          }
          {
            options.isShowReorderBtn &&
            <div
              className={'my-order-item-btn'}
              onClick={handleReorderClick}>
              {t('Reorder')}
            </div>
          }
          {
            options.isShowEditBtn &&
            <div
              className={'my-order-item-btn'}
              onClick={options.onEditClick}>
              {t('Edit')}
            </div>
          }
          {
            options.isShowVoidBtn &&
            <div
              className={'my-order-item-btn'}
              onClick={options.onVoidClick}>
              {t('Void')}
            </div>
          }
        </div>
      }
    </div>
  )
}
