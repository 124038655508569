import React, { useRef } from "react";
import Layout from '@/components/Layout'
import {useTranslation} from 'react-i18next'
import SearchProductsContent from "@/components/ProductList";
import { useSelector } from "react-redux";

import './index.less'

const SearchPage = () => {
  const {t} = useTranslation()
  const layoutRef = useRef(null)
  const productsContentRef = useRef(null)
  const orderCartProductList = useSelector(state => state.cart.orderCartProductList)

  return (
    <Layout
      ref={layoutRef}
      headerLogoutIsGoHome={true}
      isDetailPage={true}
      isShowSearch={false}
      detailPageTitle={t('My Search')}>
        <SearchProductsContent
          ref={productsContentRef}
          rootPath={'/search'}
          scrollTop={0}
          isEditOrder={false}
          cartProductList={orderCartProductList}
        />
    </Layout>
  )
}


export default SearchPage;
