export function getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
    }
    return "";
}

// 删除cookie
export function delCookie(name) {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cVal = getCookie(name);
    if (cVal != null)
        document.cookie = name + "=" + cVal + ";expires="
            + exp.toGMTString();
}

// 设置cookie
export function setCookie(cname, cValue, exDays) {
    const d = new Date();
    d.setTime(d.getTime() + (exDays* 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cValue + "; " + expires;
}
