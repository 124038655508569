import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {cloneDeep} from 'lodash';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ProductsContent from '@/components/ProductsContent'

import {getEditOrderProductList, setEditOrderIsGetOrderDetails, setEditOrderProductList} from '@/common';

export default function EditOrderProducts() {
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'edit-order-products-mobile-container' : 'edit-order-products-container'
  
  const productsContentRef = useRef(null)
  const [editOrderProducts, setEditOrderProducts] = useState([])
  const getEditOrderProductListByLocal = () => {
    setEditOrderProducts(getEditOrderProductList())
  }
  useEffect(() => {
    getEditOrderProductListByLocal()
    setEditOrderIsGetOrderDetails(false)
  }, [])
  const handlePageScroll = event => {
    productsContentRef.current?.handlePageScroll(event)
  }
  const handleConfirmClick = () => {
    setEditOrderProductList(editOrderProducts)
    navigate(-1)
  }
  const handleCancelClick = () => {
    navigate(-1)
  }
  const addProductToOrderProductList = (productItem = {}, quantity = 1) => {
    const productList = cloneDeep(editOrderProducts)
    productItem.Quantity += quantity
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      productList.push(productItem)
    } else {
      productList.splice(idx, 1, productItem)
    }
    setEditOrderProducts(productList)
  }
  const editProductByOrderProductList = (productItem = {}, quantity = 1) => {
    const productList = cloneDeep(editOrderProducts)
    productItem.Quantity = quantity
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      return
    }
    productList.splice(idx, 1, productItem)
    setEditOrderProducts(productList)
  }
  const removeProductByOrderProductList = (productItem = {}) => {
    const productList = cloneDeep(editOrderProducts)
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      return
    }
    productList.splice(idx, 1)
    setEditOrderProducts(productList)
  }
  
  return (
    <Layout
      pathnameChangeIsRestoreScrollState={false}
      isDetailPage={true}
      isShowHeaderRight={false}
      showFooter={false}
      detailPageTitle={t('Select More')}>
      <div className={rootClassName} onScroll={handlePageScroll}>
        <div className={'edit-order-products-top-container'}>
          <div className={'edit-order-products-btn-1'} onClick={handleConfirmClick}>
            {t('Confirm')}
          </div>
          <div className={'edit-order-products-btn-2'} onClick={handleCancelClick}>
            {t('Cancel')}
          </div>
        </div>
        <ProductsContent
          ref={productsContentRef}
          rootPath={'/editOrderProducts'}
          scrollTop={0}
          isEditOrder={true}
          cartProductList={editOrderProducts}
          onChangeOrderProductList={getEditOrderProductListByLocal}
          onAddProduct={addProductToOrderProductList}
          onEditProduct={editProductByOrderProductList}
          onRemoveProduct={removeProductByOrderProductList}
        />
      </div>
    </Layout>
  )
}
