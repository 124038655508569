import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {take} from 'lodash';
import {InputNumber, message, Image} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ProductItem from '@/components/ProductItem'
import OSwiper from 'src/components/OSwiper'
import noImage from '@/assets/img/icon/noImage.png'
import favoriteActiveIcon from '@/assets/img/icon/favoriteActive.png';
import favoriteDefaultIcon from '@/assets/img/icon/favoriteDefault.png';

import {
  addProductToFavoriteList,
  getMoreProducts,
  getProductDetail,
  removeProductFromFavoriteList
} from '@/api/req-api';
import {addProductToCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';
import {checkLogin, getFormatMoney, getFormatProductListByCartList, getProductItemBtnType} from '@/common';
import SkuListView from './skulist';
import ReactImageMagnify from 'react-image-magnify';

export default function ProductDetail() {
  const {id} = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const [nowImg,setNowImg] = useState('')
  const [widthData, setWidthData] = useState(window.innerWidth)
  
  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const {productCategoryMap, isLogged} = commonState
  const rootClassName = commonState.isMobile ? 'product-detail-mobile-container' : 'product-detail-container'
  const {orderCartProductList} = cartState
  const [productDetail, setProductDetail] = useState({})
  const [moreProducts, setMoreProducts] = useState([])
  const [quantity, setQuantity] = useState(1);
  const [productList, setProductList] = useState([])

  const useMoreProducts = useMemo(() => {
    return getFormatProductListByCartList(moreProducts, orderCartProductList)
  }, [moreProducts, orderCartProductList])

  const useProductList = useMemo(() => {
    return getFormatProductListByCartList(productList, orderCartProductList)
  }, [productList, orderCartProductList])
  
  const fetchDetail = async (id) => {
    try {
      const {data: [productDetailResponse]} = await getProductDetail(id)
      setProductDetail(productDetailResponse)
      setNowImg(productDetailResponse?.Images?.[0])
      setProductList(productDetailResponse.related_products || [])
      const {data: moreProductResponse} = await getMoreProducts(productDetailResponse.ProductGroup_id)
      setMoreProducts(take(moreProductResponse, 8))
    } catch (e) {
      //todo show error 
    }
  }
  const initPage = () => {
    if (id) {
      fetchDetail(id)
    }
  }
  const getPageWidth = ()=>{
    var getWidth = window.innerWidth;
    setWidthData(getWidth)
  }
  window.onresize = ()=>{
    getPageWidth()
  }
  useEffect(() => {
    initPage()
  }, [id])

  const showFavoriteIcon = productDetail.IsFavorite ? favoriteActiveIcon : favoriteDefaultIcon

  const renderProductImgSwiperSlideContent = (item, index) => {
    return <div
      className={'product-detail-top-info-left-img-container'}
      key={`product_detail_img_${index}`}>
      <img src={item} alt="" 
      className={item === nowImg ? 'detail-swiper-img detail-swiper-img-special' : 
      'detail-swiper-img'} 
      onClick={(e)=>{setNowImg(e.target.src)}}/>
    </div>
  }

  const renderProductDetailImg = () => {
    return <div
    className={'product-detail-top-info-left-img-container-top'}>
      {
        !commonState.isMobile ?  <ReactImageMagnify {...{
          smallImage: {
            alt: '',
            isFluidWidth: true,
            src: nowImg || noImage,
            width: 610,
            height: 500
          },
          largeImage: {
            src: nowImg || noImage,
            width: 915,
            height: 750,
          },
          enlargedImageContainerDimensions: {
            width: '150%',
            height: '150%',
          },
          enlargedImageContainerStyle:{
              zIndex:9999
          }
        }} /> : <Image src={nowImg || noImage} />
      }
    {productDetail.IsPromotion && <div className="product-item-promotion-icon-container">
      <img src={require('../../assets/img/icon/discountIcon.png')} alt="" />
    </div>}
    <div
      className={'product-detail-top-info-left-favorite-icon-container'}
      onClick={() => handleFavoriteIconClick(productDetail, true)}>
      <img src={showFavoriteIcon} alt="" />
    </div>
  </div>
  }

  const renderProductImgSwiper = () => <OSwiper
    className={'product-detail-top-info-left-product-img-swiper-container'}
    swiperClassName={'product-detail-top-info-left-product-img-swiper-swiper'}
    prevClassName={'product-detail-top-info-left-product-img-swiper-prev'}
    nextClassName={'product-detail-top-info-left-product-img-swiper-next'}
    data={productDetail?.Images && productDetail.Images.length > 0 ? productDetail.Images : [noImage]}
    renderSwiperSlideContent={renderProductImgSwiperSlideContent}
    // isShowPagination={true}
    // isShowNavigation={false}
    isShowPagination={false}
    isShowNavigation={true}
    slidesPerView={commonState.isMobile ? 2 : Math.floor(window.innerWidth/350)}

  />

  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem
      img={item.Thumbnail}
      price={item.Price}
      name={item.Name}
      description={item.Description}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      onBtnClick={() => handleBeforeAddToCart(item, 1)}
      onProductItemClick={() => handleProductClick(item)}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => handleAddToCart(item)}
      isPromotion={item.IsPromotion}
      stock={item.StockLevel}
      isOverSaleable={item.IsOverSaleable}
      discountDescription={item.DiscountDescription}
      originalPrice={item.OriginalPrice}
    />
  }
  const renderProductList = () => <OSwiper
    className={'product-detail-top-product-list-container'}
    data={useProductList}
    renderSwiperSlideContent={renderSwiperSlideContent}
    slidesPerView={commonState.isMobile ? 2 : 4}
  />

  const handleMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return dispatch(removeCartItem({item}))
    }
    dispatch(updateCartItemQuantity({item, quantity: newQty}))
  }
  const handleAddToCart = (item, qty) => {
    dispatch(addProductToCart({item, quantity: qty}))
  }
  const handleBeforeAddToCart = (item, qty) => {
    checkLogin(
      navigate,
      commonState.isLogged,
      location,
      () => handleAddToCart(item, qty)
    )
  }

  const handleChangeFavoriteStatus = (item) => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    fun(item).then(() => {
      message.success(msg)
      initPage()
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = (item, isProductDetail = false) => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item, isProductDetail))
  }
  
  const handleProductClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    // if (location.pathname === path) {
    //   return
    // }
    navigate(path);
  }
  
  const renderProductGroupItem = (item, index) => <div
    className={'product-detail-more-product-item-container'}
    key={`product_group_2_item_${index}`}>
    <ProductItem 
        img={item.Thumbnail} 
        price={item.Price} 
        name={item.Name}
        description={item.Description}
        isShowPrice={isLogged}
        isFormatPriceToMoney={true}
        onBtnClick={() => handleBeforeAddToCart(item, 1)}
        onProductItemClick={() => handleProductClick(item)}
        isFavorited={item.IsFavorite}
        isShowFavorite={true}
        onFavoriteIconClick={() => handleFavoriteIconClick(item)}
        quantity={item.Quantity}
        btnType={getProductItemBtnType(item.Quantity)}
        onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
        onPlusBtnClick={() => handleAddToCart(item)}
        isPromotion={item.IsPromotion}
        stock={item.StockLevel}
        isOverSaleable={item.IsOverSaleable}
        discountDescription={item.DiscountDescription}
        originalPrice={item.OriginalPrice}
    />
  </div>
  const renderRichTextDescription = () => {
    if (!!productDetail?.rich_text_description) {
      return <div className={'product-detail-rich-text-description-container'}>
        <div className={'product-detail-rich-text-description-title'}>{t('Description')}</div>
        <div className="product-detail-rich-text-description-content" dangerouslySetInnerHTML={{__html: productDetail?.rich_text_description}}/>
      </div>
    }
  }
  const renderMoreProduct = () => <div className={'product-detail-more-product-container'}>
    <div className={'product-detail-more-product-title'}>{t('MORE PRODUCT')}</div>
    <div className={'product-detail-more-product-list'}>
      {useMoreProducts.map((item, index) => renderProductGroupItem(item, index))}
    </div>
  </div>
  const renderProductInfo = () => {
    return <div className={'product-detail-top-info-right-container'}>
    <div className={'product-detail-top-info-right-ref-no'}>{t('Ref No')}#: {productDetail?.RefNum}</div>
    {
      isLogged && <div className={'product-detail-top-info-right-price'}>
        <span className={'price'}>
          {`$${getFormatMoney(productDetail.Price)}`}
        </span>
      </div>
    }
    <dis className={'product-detail-top-info-right-product-description-item'}>{t("Price is subject to vendor receipt,and the price on web is refer to the previous day.")}</dis>
    {hasPromotion && <div className={'product-detail-top-info-right-product-promotion-container'}>
      <div className={'product-detail-top-info-right-product-promotion-title'}>
        {t('Promotion')}
      </div>
      <div className={'product-detail-top-info-right-product-promotion-item'}>
        {productDetail?.DiscountDescription.map(description => <div>
          {description}
        </div>)}
      </div>
    </div>}
    <div className={'product-detail-top-info-right-product-description-container'}>
      <div className={'product-detail-top-info-right-product-description-title'}>
        {t('Product Description')}
      </div>
      <div className={'product-detail-top-info-right-product-description-item'}>
        {productDetail?.Description}
      </div>
    </div>
    <div className={'product-detail-top-info-right-product-description-con_row'}>
      <div className={'product-detail-top-info-right-product-description-title'}>
        {t('Pkg Size')}
      </div>
      <SkuListView data= {productDetail} changeSku={(detail)=>{
        setProductDetail(detail)
      }
        }/>
    </div>
    <div className={'product-detail-top-info-right-bottom-container'}>
      <div className={'product-detail-top-info-right-bottom-left-container'}>
        <div className={'product-detail-top-info-right-bottom-left-label'}>{t('QTY')}</div>
        <InputNumber
          className={'product-detail-top-info-right-bottom-left-input'}
          min={1}
          max={productDetail?.IsOverSaleable ? undefined : productDetail?.StockLevel}
          defaultValue={quantity}
          onChange={setQuantity}
          disabled={isOutOfStock}
        />
        <div className={'product-detail-top-info-right-bottom-left-label'}>{productDetail?.PackageType}</div>
      </div>
      {isOutOfStock ? <div
          className={'product-detail-top-info-right-bottom-btn-disabled'}>
        {t('Out of stock')}
      </div> : <div
        className={'product-detail-top-info-right-bottom-btn'}
        onClick={() => handleBeforeAddToCart(productDetail, quantity)}>
        {t('Add to cart')}
      </div>}
    </div>
  </div>
  }
  const isOutOfStock = productDetail?.IsOverSaleable ? false : productDetail?.StockLevel <= 0
  const hasPromotion = productDetail?.DiscountDescription && productDetail?.DiscountDescription?.length > 0;
  return (
    <Layout
      isDetailPage={true}
      detailPageTitle={productDetail?.Name}>
      <div className={rootClassName}>
        <div className={'product-detail-top-container'}>
          <div className={'product-detail-top-title'}>
            <span>{productDetail?.ProductCategory1_id ? productCategoryMap[productDetail.ProductCategory1_id]?.Name : ''}</span>
            <div>{productDetail?.Name}</div>
          </div>
          <div className={'product-detail-top-info-container'}>
            <div className={'product-detail-top-info-left-container'}>
              {renderProductDetailImg()}
              {renderProductImgSwiper()} 
            </div>
            {/* {widthData > 1300 ? renderProductInfo() : null} */}
            {renderProductInfo()}
          </div>
        </div>
        {renderRichTextDescription()}
        {renderMoreProduct()}
      </div>
    </Layout>
  )
}
